export const resources = {
  en: {
    mainMenuList: {
      Dashboard: "Dashboard",
      "User Registration": "User Registration",
      "Bank Details": "Bank Details",
      Transaction: "Transaction",
      "User Ledger": "User Ledger",
      CashBook: "CashBook",
    },
    subMenuList: {
      "Add User": "Add User",
      "User Report": "User Report",
      "Add Bank": "Add Bank",
      "Bank Report": "Bank Report",
      Transaction: "Transaction",
      Report: "Report",
      "Ledger Report": "Ledger Report",
      "Add CashBook": "Add CashBook",
      "CashBook Report": "CashBook Report",
      "User Transfer": "User Transfer",
    },
    profile: {
      Address: "Address",
      Profile: "Profile",
      EmailID: "Email ID",
      "User Name": "User Name",
      "Mobile Number": "Mobile Number",
      "Profile Image": "Profile Image",
      "Click here to upload image": "Click here to upload image",
    },
    BankDetailsScreen: {
      "Add Bank Account": "Add Bank Account",
      "Bank Name": "Bank Name",
      "Account Holder Name": "Account Holder Name",
      "Account Number": "Account Number",
      "IFSC Code": "IFSC Code",
      Cancel: "Cancel",
      Update: "Update",
      Add: "Add",
      "Bank Details Report": "Bank Details Report",
      "From Date": "From Date",
      FromDate: "FromDate",
      ToDate: "ToDate",
      "To Date": "To Date",
      Submit: "Submit",
      "Bank name is required": "Bank name is required",
      Edit: "Edit",
      Delete: "Delete",
    },
    AddUserScreen: {
      "Add User": "Add User",
      "User Name": "User Name",
      UserType: "UserType",
      "Mobile Number": "Mobile Number",
      "Email ID": "Email ID",
      "GST Number": "GST Number",
      "Pan Number": "Pan Number",
      "Aadhar Number": "Aadhar Number",
      "Flat/Building Number,Name": "Flat/Building Number,Name",
      "Area/Locality": "Area/Locality",
      City: "City",
      "State Name": "State Name",
      Cancel: "Cancel",
      Update: "Update",
      "ADD USER": "ADD USER",
      "Mobile Number is required": "Mobile Number is required",
      "User name is required": "User name is required",
      "Select Role": "Select Role",
      "Select State": "Select State",
      "ADD ADDRESS, GSTIN & PERSONAL DETAILS (OPTIONAL)":
        "ADD ADDRESS, GSTIN & PERSONAL DETAILS (OPTIONAL)",
    },
    userReportScreen: {
      "User Report": "User Report",
      "Selection Criteria": "Selection Criteria",
      "Mobile Number": " Mobile Number",
      "User Name": "UserName",
      "User Type": "UserType",
      "Email ID": "EmailID",
      "GST Number": "GST Number",
      "Pan Number": "Pan Number",
      "Aadhar Number": "Aadhar Number",
      City: "City",
      "State Name": "State Name",
    },
    AddGaveGotScreen: {
      Transaction: "Transaction",
      "Transaction Report": "Transaction Report",
      UserName: "UserName",
      "Enter Amount": "Enter Amount",
      "Discount Amount": "Discount Amount",
      "Enter Details(Remark, bill no, quantity ,etc)":
        "Enter Details(Remark, bill no, quantity ,etc)",
      "Enter Product details (Purchase product against payment)":
        "Enter Product details (Purchase product against payment)",
      DOC: "DOC",
      "Attach bills": "Attach bills",
      "Click here to upload Image": "Click here to upload Image",
      Save: "Save",
      "You Got": "PayBack Grant",
      "You Gave": "Credit Grant",
      "OS Amount": "OS Amount",
      Remark: "Remark",
      "View Details": "View Details",
      Date: "Date",
      "Select User": "Select User",
    },
    addLedgerReport: {
      "User Ledger Report": "User Ledger Report",
      "Start Date": "Start Date",
      "End Date": "End Date",
      UserName: "UserName",
      ShortName: "ShortName",
      Search: "Search",
      "Date & Time": "Date & Time",
      "Opening Balance": "Opening Balance",
      Amount: "Amount",
      Discount: "Discount",
      "Closing Balance": "Closing Balance",
      Type: "Type",
      Remark: "Remark",
      View: "View",
      "Select user": "Select user",
      "Select Wallet": "Select Wallet",
    },
    CashBookDetails: {
      CashBook: "CashBook",
      "View Report": "View Report",
      Date: "Date",
      "Payment Mode": "Payment Mode",
      Name: "Name",
      OUT: "OUT",
      IN: "IN",
      Edit: "Edit",
      Delete: "Delete",
      "Total Balance": "Total Balance",
      "Todays Balance": "Todays Balance",
      "No transaction selected": "No transaction selected",
    },
    InOutEntryCashBook: {
      "In Entry": "In Entry",
      "Out Entry": "Out Entry",
      "Enter Amount": "Enter Amount",
      "Payment Mode": "Payment Mode",
      "Enter Details(Remark, bill no, quantity, etc)":
        "Enter Details(Remark, bill no, quantity, etc)",
      "Enter Product details (Purchase product against payment)":
        "Enter Product details (Purchase product against payment)",
      Date: "Date",
      "Attach bills": "Attach bills",
      Update: "Update",
      Save: "Save",
    },
    CashBookReport: {
      "CashBook Report": "CashBook Report",
      Period: "Period",
      Start: "Start",
      End: "End",
      "Total In": "Total In",
      "Total Out": "Total Out",
      "Net Balance": "Net Balance",
      DATE: "DATE",
      "TOTAL IN": "TOTAL IN",
      "TOTAL OUT": "TOTAL OUT",
      BALANCE: "BALANCE",
    },
    userTransfer: {
      "User Transfer": "User Transfer",
      "Select Old FOS": "Select Old FOS",
      "Select New FOS": "Select New FOS",
      "Select user": "Select user",
      "Select new user": "Select new user",
      Transfer: "Transfer",
      Reset: "Reset",
      UserName: "User Name",
      UserType: "User Type",
    },
  },
  mr: {
    mainMenuList: {
      Dashboard: "डॅशबोर्ड",
      "User Registration": "वापरकर्ता नोंदणी",
      "Bank Details": "बँक तपशील",
      Transaction: "व्यवहार",
      "User Ledger": "वापरकर्ता खातेवही",
      CashBook: "नकद पुस्तिका",
    },
    subMenuList: {
      "Add Slider": "स्लाइडर जोडा",
      "Add User": "वापरकर्ता जोडा",
      "User Report": "वापरकर्ता अहवाल",
      "Add Bank": "बँक जोडा",
      "Bank Report": "बँक अहवाल",
      Transaction: "आर्थिक व्यवहार",
      Report: "अहवाल",
      "Ledger Report": "खातेवही अहवाल",
      "Add CashBook": "नकद पुस्तिका जोडा",
      "CashBook Report": "नकद पुस्तिका अहवाल",
      "User Transfer": "वापरकर्ता हस्तांतरण",
    },
    profile: {
      Address: "पत्ता",
      Profile: "प्रोफाइल",
      EmailID: "ईमेल आयडी",
      "User Name": "वापरकर्ता नाव",
      "Mobile Number": "मोबाईल नंबर",
      "Profile Image": "प्रोफाइल प्रतिमा",
      "Click here to upload image": "इमेज अपलोड करण्यासाठी येथे क्लिक करा",
    },
    BankDetailsScreen: {
      "Add Bank Account": "बँक खाते जोडा",
      "Bank Name": "बँकेचे नाव",
      "Account Holder Name": "खाते धारकाचे नाव",
      "Account Number": "खाते क्रमांक",
      "IFSC Code": "IFSC कोड",
      Cancel: "रद्द करा",
      Update: "अपडेट",
      Add: "जोडा",
      "Bank Details Report": "बँक तपशील अहवाल",
      "From Date": "या तारखेपासून",
      FromDate: "या तारखेपासून",
      ToDate: "तारखेपर्यंत",
      "To Date": "तारखेपर्यंत",
      Submit: "सबमिट करा",
      "Bank name is required": "बँकेचे नाव आवश्यक आहे",
      Edit: "संपादित करा",
      Delete: "हटवा",
    },
    AddUserScreen: {
      "Add User": "वापरकर्ता जोडा ",
      "User Name": "वापरकर्त्याचे नाव",
      UserType: "वापरकर्ता प्रकार",
      "Mobile Number": "मोबाइल नंबर",
      "Email ID": "ईमेल आयडी",
      "GST Number": "जीएसटी क्रमांक",
      "Pan Number": "पॅन नंबर",
      "Aadhar Number": "पॅन नंबर",
      "Flat/Building Number,Name": "फ्लॅट/बिल्डिंग नंबर,नाव",
      "Area/Locality": "क्षेत्र/परिसर",
      City: "शहर",
      "State Name": "राज्याचे नाव",
      Cancel: "रद्द करा",
      Update: "अपडेट",
      "ADD USER": "वापरकर्ता जोडा",
      "Mobile Number is required": "मोबाइल नंबर आवश्यक आहे",
      "User name is required": "वापरकर्त्याचे नाव आवश्यक आहे",
      "Select Role": "भूमिका निवडा",
      "Select State": "राज्य निवडा",
      "ADD ADDRESS, GSTIN & PERSONAL DETAILS (OPTIONAL)":
        "पत्ता, जीएसटी आणि वैयक्तिक तपशील जोडा (पर्यायी)",
    },
    userReportScreen: {
      "User Report": "वापरकर्ता अहवाल",
      "Selection Criteria": "निवड निकष",
      "Mobile Number": "मोबाईल क्रमांक",
      "User Name": "वापरकर्त्याचे नाव",
      "User Type": "वापरकर्ता प्रकार",
      "Email ID": "ईमेल आयडी",
      "GST Number": "जीएसटी क्रमांक",
      "Pan Number": "पॅन क्रमांक",
      "Aadhar Number": "आधार क्रमांक",
      City: "शहर",
      "State Name": "राज्याचे नाव",
    },
    AddGaveGotScreen: {
      Transaction: "आर्थिक व्यवहार",
      "Transaction Report": "आर्थिक व्यवहार अहवाल",
      UserName: "वापरकर्त्याचे नाव",
      "Enter Amount": "रक्कम प्रविष्ट करा",
      "Discount Amount": "सवलत रक्कम",
      "Enter Details(Remark, bill no, quantity ,etc)":
        "तपशील प्रविष्ट करा (शेरा, बिल क्रमांक, प्रमाण, इ.)",
      "Enter Product details (Purchase product against payment)":
        "उत्पादनाचे तपशील प्रविष्ट करा (देयकाच्या बदल्यात उत्पादन खरेदी)",
      DOC: "दिनांक",
      "Attach bills": "बिले जोडा",
      "Click here to upload Image": "प्रतिमा अपलोड करण्यासाठी येथे क्लिक करा",
      Save: "जतन करा",
      "Credit Grant": "जमा  अनुदान",
      "PayBack Grant": "पेबॅक अनुदान",
      "OS Amount": "थकबाकी रक्कम",
      Remark: "शेरा",
      "View Details": "तपशील पहा",
      Date: "तारीख",
      "Select User": "वापरकर्ता निवडा",
    },
    addLedgerReport: {
      "User Ledger Report": "वापरकर्ता लेजर अहवाल",
      "Start Date": "सुरुवातीची तारीख",
      "End Date": "शेवटची तारीख",
      UserName: "वापरकर्ताचे नाव",
      ShortName: "संक्षिप्त नाव",
      Search: "शोधा",
      "Date & Time": "तारीख व वेळ",
      "Opening Balance": "सुरुवातीची शिल्लक",
      Amount: "रक्कम",
      Discount: "सवलत",
      "Closing Balance": "शेवटची शिल्लक",
      Type: "प्रकार",
      Remark: "टिप्पणी",
      View: "पहा",
      "Select user": "वापरकर्ता निवडा",
      "Select Wallet": "वॉलेट निवडा",
    },
    CashBookDetails: {
      CashBook: "नकद पुस्तिका",
      "View Report": "अहवाल पहा",
      Date: "तारीख",
      "Payment Mode": "पेमेंट मोड",
      Name: "नाव",
      OUT: "पैसे गेले",
      IN: "पैसे आले ",
      Edit: "संपादन करा",
      Delete: "हटवा",
      "Total Balance": "एकूण शिल्लक",
      "Todays Balance": "आजची शिल्लक",
      "No transaction selected": "कोणताही व्यवहार निवडलेला नाही",
    },
    InOutEntryCashBook: {
      "In Entry": "प्रवेशात पैसे",
      "Out Entry": "पैसे बाहेर प्रवेश",
      "Enter Amount": "रक्कम भरा",
      "Payment Mode": "पेमेंट मोड",
      "Enter Details(Remark, bill no, quantity, etc)":
        "तपशील भरा (शेरा, बिल क्रमांक, प्रमाण, इत्यादी)",
      "Enter Product details (Purchase product against payment)":
        "उत्पादनाचा तपशील भरा (पेमेंटच्या विरोधात उत्पादन खरेदी)",
      Date: "तारीख",
      "Attach bills": "बिले जोडा",
      Update: "अपडेट",
      Save: "साठवा",
    },
    CashBookReport: {
      "CashBook Report": "नकद पुस्तिका अहवाल",
      Period: "कालावधी",
      Start: "सुरू",
      End: "शेवट",
      "Total In": "एकूण प्रवेश",
      "Total Out": "एकूण निकाल",
      "Net Balance": "शेवटची शिल्लक",
      DATE: "तारीख",
      "TOTAL IN": "एकूण प्रवेश",
      "TOTAL OUT": "एकूण निकाल",
      BALANCE: "शिल्लक",
    },
    userTransfer: {
      "User Transfer": "वापरकर्ता हस्तांतरण",
      "Select Old FOS": "जुना FOS निवडा",
      "Select New FOS": "नवीन FOS निवडा",
      "Select user": "वापरकर्ता निवडा",
      "Select new user": "नवीन वापरकर्ता निवडा",
      Transfer: "हस्तांतरण",
      Reset: "रीसेट",
      UserName: "वापरकर्त्याचे नाव",
      UserType: "वापरकर्त्याचा प्रकार",
    },
  },
  hi: {
    mainMenuList: {
      Dashboard: "डैशबोर्ड",
      "User Registration": "उपयोगकर्ता पंजीकरण",
      "Bank Details": "बैंक विवरण",
      Transaction: "लेनदेन",
      "User Ledger": "उपयोगकर्ता बहीखाता",
      CashBook: " रोकड़ बही",
    },
    subMenuList: {
      "Add Slider": "स्लाइडर जोड़ें",
      "Add User": "उपयोगकर्ता जोड़ें",
      "User Report": "उपयोगकर्ता प्रतिवेदन",
      "Add Bank": "बैंक जोड़ें",
      "Bank Report": "बैंक प्रतिवेदन",
      Transaction: "लेनदेन",
      Report: "प्रतिवेदन",
      "Ledger Report": "खाता बही प्रतिवेदन",
      "Add CashBook": "रोकड़ बही जोड़ें",
      "CashBook Report": "रोकड़ बही प्रतिवेदन",
      "User Transfer": "उपयोगकर्ता स्थानांतरण",
    },
    profile: {
      Address: "पता",
      Profile: "प्रोफाइल",
      EmailID: "ईमेल आईडी",
      "User Name": "उपयोगकर्ता नाम",
      "Mobile Number": "मोबाइल नंबर",
      "Profile Image": "प्रोफाइल छवि",
      "Click here to upload image": "छवि अपलोड करने के लिए यहाँ क्लिक करें",
    },
    BankDetailsScreen: {
      "Add Bank Account": "बैंक खाता जोड़ें",
      "Bank Name": "बैंक का नाम",
      "Account Holder Name": "खाता धारक का नाम",
      "Account Number": "खाता नंबर",
      "IFSC Code": "आईएफएससी कोड",
      Cancel: "रद्द करें",
      Update: "अपडेट करें",
      Add: "जोड़ें",
      "Bank Details Report": "बैंक विवरण प्रतिवेदन",
      "From Date": "तारीख से",
      FromDate: "तारीख से",
      ToDate: "तारीख तक",
      "To Date": "तारीख तक",
      Submit: "सबमिट करें",
      "Bank name is required": "बैंक का नाम आवश्यक है",
      Edit: "संपादित करें",
      Delete: "हटाएं",
    },
    AddUserScreen: {
      "Add User": "उपयोगकर्ता जोड़ें",
      "User Name": "उपयोगकर्ता नाम",
      UserType: "उपयोगकर्ता प्रकार",
      "Mobile Number": "मोबाइल नंबर",
      "Email ID": "ईमेल आईडी",
      "GST Number": "जीएसटी नंबर",
      "Pan Number": "पैन नंबर",
      "Aadhar Number": "आधार नंबर",
      "Flat/Building Number,Name": "फ्लैट/बिल्डिंग नंबर, नाम",
      "Area/Locality": "क्षेत्र/इलाका",
      City: "शहर",
      "State Name": "राज्य का नाम",
      Cancel: "रद्द करें",
      Update: "अपडेट करें",
      "ADD USER": "उपयोगकर्ता जोड़ें",
      "Mobile Number is required": "मोबाइल नंबर आवश्यक है",
      "User name is required": "उपयोगकर्ता नाम आवश्यक है",
      "Select Role": "भूमिका चुनें",
      "Select State": "राज्य चुनें",
      "ADD ADDRESS, GSTIN & PERSONAL DETAILS (OPTIONAL)":
        "पता, जीएसटी और व्यक्तिगत विवरण जोड़ें (वैकल्पिक)",
    },
    userReportScreen: {
      "User Report": "उपयोगकर्ता प्रतिवेदन",
      "Selection Criteria": "चयन मापदंड",
      "Mobile Number": "मोबाइल नंबर",
      "User Name": "उपयोगकर्ता नाम",
      "User Type": "उपयोगकर्ता प्रकार",
      "Email ID": "ईमेल आईडी",
      "GST Number": "जीएसटी नंबर",
      "Pan Number": "पैन नंबर",
      "Aadhar Number": "आधार नंबर",
      City: "शहर",
      "State Name": "राज्य का नाम",
    },
    AddGaveGotScreen: {
      Transaction: "लेनदेन",
      "Transaction Report": "लेनदेन प्रतिवेदन",
      UserName: "उपयोगकर्ता नाम",
      "Enter Amount": "राशि दर्ज करें",
      "Discount Amount": "छूट राशि",
      "Enter Details(Remark, bill no, quantity ,etc)":
        "विवरण दर्ज करें (टिप्पणी, बिल नंबर, मात्रा आदि)",
      "Enter Product details (Purchase product against payment)":
        "उत्पाद विवरण दर्ज करें (भुगतान के बदले उत्पाद खरीदें)",
      DOC: "तिथि",
      "Attach bills": "बिल संलग्न करें",
      "Click here to upload Image": "छवि अपलोड करने के लिए यहाँ क्लिक करें",
      Save: "सहेजें",
      "Credit Grant": "क्रेडिट अनुदान",
      "PayBack Grant": "पेबैक अनुदान",
      "OS Amount": "बकाया राशि",
      Remark: "टिप्पणी",
      "View Details": "विवरण देखें",
      Date: "तारीख",
      "Select User": "उपयोगकर्ता का चयन करें",
    },
    addLedgerReport: {
      "User Ledger Report": "उपयोगकर्ता लेजर प्रतिवेदन",
      "Start Date": "प्रारंभ तिथि",
      "End Date": "समाप्ति तिथि",
      UserName: "उपयोगकर्ता नाम",
      ShortName: "संक्षिप्त नाम",
      Search: "खोज",
      "Date & Time": "तारीख और समय",
      "Opening Balance": "आरंभिक शेष",
      Amount: "राशि",
      Discount: "छूट",
      "Closing Balance": "समाप्ति शेष",
      Type: "प्रकार",
      Remark: "टिप्पणी",
      View: "देखें",
      "Select user": "उपयोगकर्ता चुनें",
      "Select Wallet": "वॉलेट चुनें",
    },
    CashBookDetails: {
      CashBook: " रोकड़ बही",
      "View Report": "प्रतिवेदन देखें",
      Date: "तारीख",
      "Payment Mode": "भुगतान का प्रकार",
      Name: "नाम",
      OUT: "पैसा गया",
      IN: "पैसा आया",
      Edit: "संपादन करा",
      Delete: "मिटाना",
      "Total Balance": "कुल शेष",
      "Todays Balance": "आज का शेष",
      "No transaction selected": "कोई लेन-देन चयनित नहीं",
    },
    InOutEntryCashBook: {
      "In Entry": "अंदर प्रवेश",
      "Out Entry": "बाहर प्रवेश",
      "Enter Amount": "राशि दर्ज करें",
      "Payment Mode": "भुगतान मोड",
      "Enter Details(Remark, bill no, quantity, etc)":
        "विवरण दर्ज करें (टिप्पणी, बिल नंबर, मात्रा, आदि)",
      "Enter Product details (Purchase product against payment)":
        "उत्पाद विवरण दर्ज करें (भुगतान के खिलाफ उत्पाद खरीदें)",
      Date: "तारीख",
      "Attach bills": "बिल संलग्न करें",
      Update: "अपडेट",
      Save: "सहेजें",
    },
    CashBookReport: {
      "CashBook Report": "रोकड़ बही प्रतिवेदन",
      Period: "अवधि",
      Start: "आरंभ",
      End: "समाप्त",
      "Total In": "कुल आवंटन",
      "Total Out": "कुल निवेश",
      "Net Balance": "नेट संतुलन",
      DATE: "तारीख",
      "TOTAL IN": "कुल आवंटन",
      "TOTAL OUT": "कुल निवेश",
      BALANCE: "संतुलन",
    },

    userTransfer: {
      "User Transfer": "उपयोगकर्ता स्थानांतरण",
      "Select Old FOS": "पुराने FOS का चयन करें",
      "Select New FOS": "नए FOS का चयन करें",
      "Select user": "उपयोगकर्ता का चयन करें",
      "Select new user": "नए उपयोगकर्ता का चयन करें",
      Transfer: "स्थानांतरण",
      Reset: "रीसेट",
      UserName: "उपयोगकर्ता नाम",
      UserType: "उपयोगकर्ता प्रकार",
    },
  },
};
