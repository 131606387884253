import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AuthInnerNavbar from "src/component/navbar/authInnerNavbar";
import { LOGOUT } from "src/component/helper/constants";
import { useDispatch } from "react-redux";

const ChangePasswordAddScreen = lazy(
  () => import("src/pages/innerPages/change_password/changePasswordAddScreen")
);
const ProfileScreen = lazy(
  () => import("src/pages/innerPages/profile/profileScreen")
);
const AddUserScreen = lazy(
  () => import("src/pages/innerPages/add_user/addUserScreen")
);
const UserReportScreen = lazy(
  () => import("src/pages/innerPages/reports/user_Report/userReportScreen")
);
const UserTransferScreen = lazy(
  () => import("src/pages/innerPages/userTransfer/userTransferScreen")
);
const BankDetailsScreen = lazy(
  () => import("src/pages/innerPages/bank_details/bankDetailsScreen")
);
const BankDetailsReportScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/reports/bankDetails_Report/bankDetailsReportScreen"
    )
);
const UserEditScreen = lazy(
  () => import("src/pages/innerPages/reports/user_Report/editScreen")
);
const LedgerReportScreen = lazy(
  () => import("src/pages/innerPages/ledger_Report/ledgerReportScreen")
);
const AddGaveGotScreen = lazy(
  () => import("src/pages/innerPages/addgave_got/addGave_GotScreen")
);
const YouGaveScreen = lazy(
  () => import("src/pages/innerPages/you_Gave/youGaveScreen")
);
const YouGotScreen = lazy(
  () => import("src/pages/innerPages/youGot/youGotScreen")
);
const YouGaveGotReportScreen = lazy(
  () =>
    import(
      "src/pages/innerPages/reports/youGave&Got_Report/youGaveGotReportScreen"
    )
);
const AddCashBookScreen = lazy(
  () => import("src/pages/innerPages/cashBook_Details/cashBookDetailsScreen")
);
const InEntryScreen = lazy(
  () => import("src/pages/innerPages/add_InCashbook/inEntryScreen")
);
const OutEntryScreen = lazy(
  () => import("src/pages/innerPages/add_OutCashBook/OutEntryCashBook")
);
const CashBookReportScreen = lazy(
  () =>
    import("src/pages/innerPages/reports/cashBook_Report/cashBookReportScreen")
);
const AdminDashBoardScreen = lazy(
  () => import("src/pages/innerPages/admin_dashBoard/adminDashBoardScreen")
);

const MainRoute: React.FC = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(LOGOUT());
    LOGOUT();
  };

  return (
    <AuthInnerNavbar>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path="/change_password"
            element={<ChangePasswordAddScreen />}
          />
          <Route
            path="/Profile"
            element={<ProfileScreen IsUserProfileUploaded={false} />}
          />
          <Route path="/Add_User" element={<AddUserScreen />} />
          <Route path="/User_Report" element={<UserReportScreen />} />
          <Route path="/User_Transfer" element={<UserTransferScreen />} />
          <Route path="/EditScreen" element={<UserEditScreen />} />
          <Route path="/Add_Bank" element={<BankDetailsScreen />} />
          <Route path="/Bank_Report" element={<BankDetailsReportScreen />} />
          <Route path="/UserLedger_Report" element={<LedgerReportScreen />} />
          <Route path="/Transaction" element={<AddGaveGotScreen />} />
          <Route path="/CreditGrant" element={<YouGaveScreen />} />
          <Route path="/PayBackGrant" element={<YouGotScreen />} />
          <Route
            path="/Transaction_Report"
            element={<YouGaveGotReportScreen />}
          />
          <Route path="/Add_CashBook" element={<AddCashBookScreen />} />
          <Route
            path="/CashBookIN"
            element={<InEntryScreen CashbookDetailID={0} />}
          />
          <Route
            path="/CashBookOUT"
            element={<OutEntryScreen CashbookDetailID={0} />}
          />
          <Route path="/CashBook_Report" element={<CashBookReportScreen />} />
          <Route path="/AdminDashboard" element={<AdminDashBoardScreen />} />
          <Route
            path="/Logout"
            element={<LogoutComponent onLogout={handleLogout} />}
          />
        </Routes>
      </Suspense>
    </AuthInnerNavbar>
  );
};

export default MainRoute;

interface LogoutComponentProps {
  onLogout: () => void;
}

const LogoutComponent: React.FC<LogoutComponentProps> = ({ onLogout }) => {
  React.useEffect(() => {
    onLogout();
  }, [onLogout]);

  return <div>Logging out...</div>;
};
